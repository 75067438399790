import './App.css';
import { Alert, AppBar, Box, Card, CardContent, IconButton, Snackbar, Toolbar, Tooltip, Typography } from '@mui/material';
import { Identifier } from './Identifier';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Ticket, ticketService } from './ticketService';
import { VerifierElement } from './VerifierElement';
import ExitIcon from '@mui/icons-material/ExitToApp';
import ApiIcon from '@mui/icons-material/Api'; 
import { Scenarios } from './Scenarios';
import { Usage } from './Usage';

function App() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [ticket, setTicket] = useState<Ticket>()
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>()
  const [snackbarMessage, setSnackbarMessage] = useState<string>()

  const handleTicketChanged = (t: Ticket) => {
    setTicket(t)
    if (t.toast) {
      setSnackbarMessage(t.toast)
      setSnackbarOpen(true)
    }
  }

  const handleSwaggerDocClick = () => {
    const link = document.createElement("a");
    link.href = "/identityverification/swagger-ui.html";
    link.target = "_verify_swagger";
    link.click();
  }

  useEffect(() => {
    const partyid = searchParams.get('partyid')

    if (partyid) {
        ticketService.createTicket(partyid).then(t => {
          setTicket(t)
        })
    } else {
      setTicket(undefined)
    }
  }, [searchParams])

  return (<>
    <AppBar position="static">
      <Toolbar variant='dense'>
        <Typography variant="h6">
          Caller Verification Tool
        </Typography>
        {
          ticket
          ?
            <Box style={{ marginLeft: 'auto' }}><Tooltip title='Exit to main page'><IconButton color='inherit' onClick={() => setSearchParams([])}><ExitIcon /></IconButton></Tooltip></Box>
          : 
            <Box style={{ marginLeft: 'auto' }}><Tooltip title='API documentation'><IconButton color='inherit' onClick={handleSwaggerDocClick}><ApiIcon /></IconButton></Tooltip></Box>
        }
      </Toolbar>
    </AppBar>
    <Card>
      <CardContent>
        {ticket?
        <>
            <Identifier ticket={ticket}><VerifierElement ticket={ticket} onTicketChanged={handleTicketChanged} /></Identifier>
        </>
        :
          <>
            <Usage />
            <Scenarios setSearchParams={setSearchParams} />
          </>
        }
      </CardContent>
    </Card>
    <Snackbar open={snackbarOpen} /*autoHideDuration={4000}*/ onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
    </Snackbar>
  </>);
}

export default App;
