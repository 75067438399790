import { Box, Button, Grid, TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { Ticket, ticketService } from "./ticketService";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerifierUnavailableIcon from '@mui/icons-material/GppBad';
import { Verifier } from "./VerifierElement";

type VerificationMethodProps = {
    ticket: Ticket
    verifier: Verifier
    type: VerificationMethodType
    onTicketChanged(t: Ticket): void
}

export type VerificationMethodType = 'SMS' | 'VCN' | 'TOTP' | 'EMAIL'

export function VerificationMethod(props: VerificationMethodProps) {
    const [otpFieldValue, setOtpFieldValue] = useState<string>('')

    const start = (method: VerificationMethodType) => {
        ticketService.startVerification(props.ticket, method)
        .then(t => props.onTicketChanged(t))
        .catch(e => {
            console.log('failed', e)
        })
    }

    const verify = (method: VerificationMethodType, response: string) => {
        ticketService.continueVerification(props.ticket, method, response)
        .then(t => props.onTicketChanged(t))
        .catch(e => {
            console.log('failed', e)
        })
        setOtpFieldValue('')
    }

    const handleOtpFieldChange: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
        setOtpFieldValue(event.currentTarget.value)
    }

    const isValidOtp = (otp: string): boolean => {
        return otp.length === 6
    }

    switch(props.type) {
        case 'SMS':
            return (<>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '10px' }}>
                        SMS:
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextField InputProps={{ readOnly: true }} hiddenLabel variant='filled' size='small' defaultValue={props.verifier.parameters.msisdn} />
                        <Button disabled={!props.verifier.available || props.verifier.state === 'IN_PROGRESS' || props.verifier.state === 'COMPLETE'} variant="contained" onClick={() => start(props.type)}>Send</Button>
                        <TextField value={otpFieldValue} onChange={handleOtpFieldChange} disabled={!props.verifier.available || (props.verifier.state !== 'IN_PROGRESS' && props.verifier.state !== 'FAILED')} size='small' label='OTP' />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        {
                            props.verifier.available
                                ? 
                                props.verifier.state === 'COMPLETE'
                                    ? <VerifiedUserIcon color="success" />
                                    : <Button disabled={!props.verifier.available || !isValidOtp(otpFieldValue)} onClick={() => verify(props.type, otpFieldValue)} variant="contained">Verify ({props.verifier.attemptsRemaining})</Button>
                                : <VerifierUnavailableIcon fontSize="large" color="error" />
                        }
                    </Box>
                </Grid>
            </>)
        case 'EMAIL':
            return (<>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '10px' }}>
                        Email:
                    </Box>
                </Grid>
                <Grid item xs={7} justifyContent='center'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextField InputProps={{ readOnly: true }} hiddenLabel variant='filled' size='small' defaultValue={props.verifier.parameters.emailaddress} />
                        <Button disabled={!props.verifier.available || props.verifier.state === 'IN_PROGRESS' || props.verifier.state === 'COMPLETE'} variant="contained" onClick={() => start(props.type)}>Send</Button>
                        <TextField value={otpFieldValue} onChange={handleOtpFieldChange} disabled={!props.verifier.available || (props.verifier.state !== 'IN_PROGRESS' && props.verifier.state !== 'FAILED')} size='small' label='OTP' />
                    </Box>
                </Grid>
                <Grid item xs={2} justifyContent='center' alignItems={'center'}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        {
                            props.verifier.available
                                ? 
                                props.verifier.state === 'COMPLETE'
                                    ? <VerifiedUserIcon fontSize="large" color="success" />
                                    : <Button disabled={!props.verifier.available || !isValidOtp(otpFieldValue)} onClick={() => verify(props.type, otpFieldValue)} variant="contained">Verify ({props.verifier.attemptsRemaining})</Button>
                                : <VerifierUnavailableIcon fontSize="large" color="error" />
                        }
                    </Box>
                </Grid>
            </>)
        // case 'VCN':
        //     return (<>
        //         <Grid item xs={3}>
        //             <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '10px' }}>
        //                 Viewing card number:
        //             </Box>
        //         </Grid>
        //         <Grid item xs={7} justifyContent='center'>
        //             <TextField size="small" label='enter VCN'/>
        //         </Grid>
        //         <Grid item xs={2} justifyContent='center' alignItems={'center'}>
        //             <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        //                 <Button disabled variant="contained">Verify</Button>
        //             </Box>
        //         </Grid>
        //     </>)
        // case 'TOTP':
        //     return (<>
        //         <Grid item xs={3}>
        //             <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '10px' }}>
        //                 MySky security code:
        //             </Box>
        //         </Grid>
        //         <Grid item xs={7} justifyContent='center'>
        //             <TextField size="small" label='enter security code'/>
        //         </Grid>
        //         <Grid item xs={2} justifyContent='center' alignItems={'center'}>
        //             <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        //                 <Button disabled variant="contained">Verify</Button>
        //             </Box>
        //         </Grid>
        //     </>)
    }

    return <>'No such verifier'</>
}
