import CONFIG from "./environment";
import { VerificationMethodType } from "./VerificationMethod";
import { Verifier } from "./VerifierElement";

export type Verification = {
    verifiedBy: string[]
    inProgress: string
    verifiers: Verifier[]
    verified: boolean
}

export type Ticket = {
    id: string
    status: 'FAILED' | 'IDENTIFIED' | 'NOT_IDENTIFIED' | 'VERIFIED'
    identification: []
    verification: Verification
    customerData: { name: string, address: string[], msisdn: string, email: string }
    toast?: string
}

export type Scenario = {
    name: string
    description: string
    idType: string
    idValue: string
}

class TicketService {
    createTicket(partyid: String): Promise<Ticket> {
        return fetch(
            `${CONFIG.SERVER}/ticket`,
            {
                body:`{"identifiers":[{"type":"partyid", "value":"${partyid}"}]}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(r => r.json())
    }

    startVerification(ticket: Ticket, method: VerificationMethodType): Promise<Ticket> {
        return fetch(
            `${CONFIG.SERVER}/ticket/${ticket.id}/verification`,
            {
                body: `{"method":"${method}"}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(r => r.json())
    }

    continueVerification(ticket: Ticket, method: VerificationMethodType, response: string): Promise<Ticket> {
        return fetch(
            `${CONFIG.SERVER}/ticket/${ticket.id}/verification`,
            {
                body: `{"method":"${method}","response":"${response}"}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(r => r.json())
    }

    scenarios(): Promise<Scenario[]> {
        return fetch(
            `${CONFIG.SERVER}/scenarios`,
        )
        .then(r => r.json())
    }
}

export const ticketService = new TicketService()
