import { Box, Grid, Typography } from "@mui/material";

export function Usage() {
    return <>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    <Typography>Usage information:</Typography>
                </Box>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
                    <Typography variant="body2">This tool is for demonstration purposes only. It makes no external calls to fetch or send information (it does not actually send any emails, SMS etc.).</Typography>
                </Box>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
                    <Typography variant="body2">All characters appearing in this work are fictitious. Any resemblance to real persons, living or dead, is purely coincidental.</Typography>
                </Box>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px' }}>
                    <Typography variant="body2">Any One Time Passcode (OTP) begining with the digit 7 is considered valid.</Typography>
                </Box>
            </Grid>
        </Grid>
    </>
}