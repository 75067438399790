import { Box, Grid, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Ticket } from "./ticketService";
import { VerificationMethod } from "./VerificationMethod";

export type Verifier = {
    type: 'EMAIL'
    parameters: any
    state: 'IN_PROGRESS' | 'COMPLETE' | 'FAILED'
    available: false
    attemptsRemaining: number
}

export const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export type VerifierProps = {
    ticket: Ticket
    onTicketChanged(t: Ticket): void
}

export function VerifierElement(props: VerifierProps) {
    const ticket = props.ticket

    const headerColour = ticket.status === 'FAILED' ? 'red' : ticket.status !== 'IDENTIFIED' ? 'green' : 'orange'

    return <>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={8}>
                <Item elevation={0} style={{ textAlign: 'center' }}><Typography variant='h6'>Verification Process: <span style={{ color: headerColour }}>{ticket.status === 'FAILED' ? 'FAILED' : ticket.status === 'IDENTIFIED' ? 'IN PROGRESS' : 'COMPLETE'}</span></Typography></Item>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
            {
                ticket.verification.verifiers.length ?
                    ticket.verification.verifiers.map(v => <VerificationMethod verifier={v} ticket={ticket} type={v.type} onTicketChanged={props.onTicketChanged} />)
                :
                    <>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px', color: 'red' }}>
                                <Typography variant='h6'>There are no available verifiers.</Typography>
                            </Box>
                        </Grid>
                    </>
            }
        </Grid>
    </>
}