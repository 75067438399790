type configtype = {
    SERVER: string,
}

const dev: configtype = {
    // SERVER: 'http://localhost:8080/identityverification',
    SERVER: '/identityverification',
}

const prod: configtype = {
    SERVER: '/identityverification',
}

let config: configtype = process.env.NODE_ENV === 'production' ? prod : dev

const CONFIG: configtype = { ...config }

console.log('=======================================')
console.info(`NODE_ENV:${process.env.NODE_ENV}`)
console.log(config)

export default CONFIG
