import { Box, Divider, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { MouseEventHandler, useEffect, useState } from "react";
import { Scenario, ticketService } from "./ticketService";

export type ScenariosProps = {
    setSearchParams: (s: any) => any
}

export function Scenarios(props: ScenariosProps) {
    const handleListItemClick: MouseEventHandler<HTMLDivElement> = (event) => {
        const scenario = Number(event.currentTarget.id.substring(9))
        if (scenarios?.[scenario]) {
            props.setSearchParams(`${scenarios[scenario].idType}=${scenarios[scenario].idValue}`)
        }
    }

    const [scenarios, setScenarios] = useState<Scenario[]>()

    useEffect(() => {
        if (!scenarios) {
            ticketService.scenarios().then(s => setScenarios(s))
        }
    }, [scenarios])

    return <>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px' }}>
                    <Typography>Choose a test scenario:</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px' }}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <Divider component="li" />
                        {
                            scenarios?.map((s,n) => <>
                                <ListItemButton id={`scenario_${n}`} alignItems="flex-start" onClick={handleListItemClick}>
                                    <ListItemText
                                        primary={s.name}
                                        secondary={s.description}
                                    />
                                </ListItemButton>
                                <Divider component="li" />
                            </>)
                        }
                    </List>
                </Box>
            </Grid>
        </Grid>
    </>
}