import { Grid, TextField, Typography } from "@mui/material";
import { Ticket } from "./ticketService";
import { Item } from "./VerifierElement";

export type IdentiferProps = {
    children: JSX.Element
    ticket: Ticket
}

export function Identifier(props: IdentiferProps) {
    const ticket = props.ticket

    const headerColour = ticket.status !== 'NOT_IDENTIFIED' ? 'green' : 'red'
    
    return <>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={8}>
                <Item elevation={0} style={{ textAlign: 'center' }}><Typography variant='h6'>Identification Process: <span style={{ color: headerColour }}>{ticket.status !== 'NOT_IDENTIFIED'?'COMPLETE':'NOT IDENTIFIED'}</span></Typography></Item>
            </Grid>
        </Grid>
        { ticket.status !== 'NOT_IDENTIFIED' ? <>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={8}>
                    <Item elevation={0}><Typography>Caller identified as:</Typography></Item>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={2} alignContent={''}>
                    <Item elevation={0}>Name:</Item>
                </Grid>
                <Grid item xs={5}>
                    <Item elevation={0}><TextField InputProps={{ readOnly: true }} hiddenLabel variant='filled' size='small' fullWidth defaultValue={ticket.customerData.name} /></Item>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={2}>
                    <Item elevation={0}>Address:</Item>
                </Grid>
                <Grid item xs={5}>
                    <Item elevation={0}><TextField InputProps={{ readOnly: true }} hiddenLabel variant='filled' size='small' fullWidth multiline defaultValue={ticket.customerData.address.join('\n')} /></Item>
                </Grid>
            </Grid>
            {props.children}
            </> : undefined
        }
    </>
}